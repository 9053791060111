import { disablePageScroll, enablePageScroll } from "scroll-lock";

export default function toggleMobileMenu() {
  let button = $('.header__button');
  let menu = $('.mobMenu');
  let header = $('.header');
  let items = $('.mobMenu__link a');
  let dropdownContainer = $('.dropdown');
  let dropdownTrigger = $('.drop__triggerMob');
  let $scrollableElement = document.querySelector('.mobMenu__links');
  dropdownTrigger.on('click', function() {
    $(this).children().next().toggleClass('active');
    dropdownContainer.slideToggle();
  });
  function hideMenu() {
    enablePageScroll();
    header.removeClass('active');
    menu.removeClass('active');
    button.children().removeClass('active');
  }
  button.on('click', function() {
    if (menu.hasClass('active')) {
      hideMenu();
    } else {
      header.addClass('active');
      menu.addClass('active');
      button.children().addClass('active');
      disablePageScroll($scrollableElement);
    }
  });
  Array.from(items).forEach(function(element) {
    $(element).on('click', function() {
      hideMenu();
    });
  });
  $(window).on('resize', function() {
    if ($(window).width() >= 1140 && menu.hasClass('active')) {
      hideMenu();
    }
  });
}
toggleMobileMenu();