import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

export default function sliderClients() {
  new Swiper(".hero__clients", {
    modules: [Autoplay],
    speed: 3000,
    slidesPerView: 3,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
    breakpoints: {
      746: {
        slidesPerView: 3,
      },
      1140: {
        slidesPerView: 5,
      },
    },
  });
}
sliderClients();